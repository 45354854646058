$(document).on('turbolinks:load', function () {
  function fetchOrderCounts() {
    if (window.location.pathname.includes('v2_order_dispatches/shipped')) {
      return;
    }
    var params = new URLSearchParams(window.location.search);
    var queryString = params.toString();

    $.ajax({
      url: '/v2_order_dispatches/order_counts?' + queryString,
      method: 'GET',
      dataType: 'json',
      success: function(data) {
        $('#unmapped_and_unable_orders_count').text((data.unmapped_orders_count || 0) + (data.unable_to_find_orders_count || 0));
        $('#unmapped_orders_count').text(data.unmapped_orders_count || 0);
        $('#deferral_orders_count').text(data.deferral_orders_count || 0);
        $('#unshipped_orders_count').text(data.unshipped_orders_count || 0);
        // $('#completed_orders_count').text(data.completed_orders_count || 0);
        $('#canceled_orders_count').text(data.canceled_orders_count || 0);
        $('#pending_orders_count').text(data.pending_orders_count || 0);
        $('#unable_orders_count').text(data.unable_to_find_orders_count || 0);
        $('#ready_to_dispatch_count').text(data.ready_to_dispatch_count || 0);
        $('#ship_by_today_count').text(data.ship_by_today_count || 0);
        $('#prime_count').text(data.prime_orders_count || 0);
        $('#ready_to_pack_count').text(data.ready_to_pack_count || 0);
        $("#premium_count").text((data.premium_orders_count || 0) + (data.prime_orders_count || 0));
      },
      error: function(error) {
        console.error('Error fetching order counts:', error);
      }
    });
  }
  if (window.location.pathname.includes('v2')) {
    setTimeout(fetchOrderCounts, 1000);
  }
});
